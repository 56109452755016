import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

export default function Podcasts () {

  return (
    <Layout>

      <SEO 
        title="Podcasts"
        description="When you want something to listen to but only half pay attention to."
      />

        <div className="page-heading-box">
          <h1>PODCASTS</h1>
        </div>

        <div className="container">

          <div className="container-item">
            <h2>Top Recommended Podcasts</h2>
            <p>An ever growing list of the top podcasts to watch or listen to. The genres range from entertainment. With podcasts growing in popularity, more and more people are listening and with bigger audiences. New shows are being created all the time and current podcasts just keep getting better.</p>
            <ul>
              <li><a href="http://podcasts.joerogan.net/">The Joe Rogan Experience</a></li>
              <li><a href="https://www.hotboxinpodcast.com/">Hotboxin' with Mike Tyson</a></li>
              <li><a href="https://www.tfatk.com/tour-encore">The Fighter and the Kid</a></li>
              <li><a href="https://art19.com/shows/king-and-the-sting">King and the Sting</a></li>
            </ul>
          </div>

          <div className="container-item mt-5">
            <h3>The Joe Rogan Experience</h3>
            <ul>
              <li><a href="https://www.youtube.com/user/PowerfulJRE">Youtube</a></li>
              <li><a href="https://open.spotify.com/show/4rOoJ6Egrf8K2IrywzwOMk">Spotify</a></li>
            </ul>
            <p>
              The Joe Rogan Experience is one of the biggest podcasts out there. It's in a lane of it's own due to the variety of guests and topics. Most episodes go for about 2-3 hours with a few only being 1 hour and some creeping into the 4+ hour range. 
            </p>
          </div>

          <div className="container-item mt-5">
            <h3>Hotboxin' with Mike Tyson</h3>
            <ul>
              <li><a href="https://www.youtube.com/channel/UCdtNjOwfQpgVK0FyOeLyzrg">Youtube</a></li>
              <li><a href="https://open.spotify.com/show/7rrXnDyc70sfTcJqBO32lr">Spotify</a></li>
            </ul>
            <p>The champ is back in the limelight, this time as a host of his very own podcast. Now in a different place in life, the most recognizable face in sports is now the face of wisdom and crazy stories from his younger days.</p>
          </div>

        </div>
    
    </Layout>
  )
}